var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group row" }, [
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Api Key")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.firebase.api_key,
              expression: "firebase.api_key"
            }
          ],
          ref: "api_key",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.firebase.api_key },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.firebase, "api_key", $event.target.value)
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Auth Domain")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.firebase.auth_domain,
            expression: "firebase.auth_domain"
          }
        ],
        ref: "auth_domain",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.firebase.auth_domain },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.firebase, "auth_domain", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Database Url")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.firebase.database_url,
            expression: "firebase.database_url"
          }
        ],
        ref: "database_url",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.firebase.database_url },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.firebase, "database_url", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Project Id")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.firebase.peoject_id,
            expression: "firebase.peoject_id"
          }
        ],
        ref: "peoject_id",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.firebase.peoject_id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.firebase, "peoject_id", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Storage Bucket")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.firebase.storage_bucket,
            expression: "firebase.storage_bucket"
          }
        ],
        ref: "storage_bucket",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.firebase.storage_bucket },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.firebase, "storage_bucket", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Message Sender Id")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.firebase.messaging_sender_id,
            expression: "firebase.messaging_sender_id"
          }
        ],
        ref: "messaging_sender_id",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.firebase.messaging_sender_id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.firebase, "messaging_sender_id", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("App Id")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.firebase.app_id,
            expression: "firebase.app_id"
          }
        ],
        ref: "app_id",
        staticClass: "form-control border-dark",
        attrs: { type: "email", placeholder: "" },
        domProps: { value: _vm.firebase.app_id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.firebase, "app_id", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("br"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit" },
          on: {
            click: function($event) {
              return _vm.updateSetting()
            }
          }
        },
        [_vm._v("Submit")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }