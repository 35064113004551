var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "\n                  card card-custom\n                  gutter-b\n                  bg-transparent\n                  shadow-none\n                  border-0\n                "
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "\n                    card-header\n                    align-items-center\n                    border-bottom-dark\n                    px-0\n                  "
                      },
                      [
                        _c("div", { staticClass: "card-title mb-0" }, [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "card-label mb-0 font-weight-bold text-body"
                            },
                            [
                              _vm._v(
                                "\n                      Assign Permissons To " +
                                  _vm._s(_vm.$route.params.name) +
                                  "\n                    "
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0"
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "row" },
                          _vm._l(_vm.permissions, function(permission, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "col-md-3" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-control custom-checkbox"
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "custom-control-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: "permission" + i
                                      },
                                      domProps: {
                                        value: permission.id,
                                        checked: _vm.selectedPermission.includes(
                                          permission.id
                                        )
                                          ? "checked"
                                          : ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.check($event)
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "custom-control-label",
                                        attrs: { for: "permission" + i }
                                      },
                                      [_vm._v(_vm._s(permission.name))]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c("hr"),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            on: { click: _vm.addUpdateRolePermissions }
                          },
                          [
                            _vm._v(
                              "\n                        Save\n                    "
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }