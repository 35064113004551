var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade text-left",
      class: { show: _vm.showModal },
      staticStyle: { overflow: "scroll" },
      style: [
        _vm.showModal ? { display: "block !important" } : { display: "none" }
      ],
      attrs: {
        id: "imagepopup",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel1"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-xl ", attrs: { role: "document" } },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass:
                    "close rounded-pill btn btn-sm btn-icon btn-light btn-hover-primary m-0",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close"
                  },
                  on: {
                    click: function($event) {
                      return _vm.toggleModal()
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "bi bi-x",
                      attrs: {
                        width: "20px",
                        height: "20px",
                        viewBox: "0 0 16 16",
                        fill: "currentColor",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          d:
                            "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "d-flex flex-column-fluid" }, [
                _c("div", { staticClass: "container-fluid" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "gallary-categories" }, [
                        _c(
                          "ul",
                          {
                            staticClass:
                              "nav nav-pills justify-content-start  mb-0",
                            attrs: { id: "pills-tab", role: "tablist" }
                          },
                          [
                            _c("li", { staticClass: "nav-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "nav-link  btn-light-dark shadow-none mr-4 mb-4",
                                  class: { active: _vm.tag_id == "" },
                                  attrs: {
                                    id: "All-tab-center",
                                    "data-toggle": "pill",
                                    href: "#All-center",
                                    role: "tab",
                                    "aria-controls": "All-center",
                                    "aria-selected": "true"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.gallaryByTagId("")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\r\n                                                All\r\n                                            "
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.tags, function(tag) {
                              return _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "nav-link  btn-light-dark shadow-none mr-4 mb-4",
                                    class: { active: _vm.tag_id == tag.tag_id },
                                    attrs: {
                                      id: "general-tab-center",
                                      "data-toggle": "pill",
                                      href: "#" + tag.tag_name,
                                      role: "tab",
                                      "aria-controls": "general",
                                      "aria-selected": "false"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.gallaryByTagId(tag.tag_id)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\r\n                                                " +
                                        _vm._s(tag.tag_name) +
                                        "\r\n                                            "
                                    )
                                  ]
                                )
                              ])
                            })
                          ],
                          2
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 " }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "gallary0 linked card card-custom gutter-b bg-white border-0",
                          attrs: { id: "generalgallary" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "tab-content",
                              attrs: { id: "v-pills-tabContent" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "tab-pane fade show active",
                                  attrs: {
                                    id: "All-center",
                                    role: "tabpanel",
                                    "aria-labelledby": "All-tab-center"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "card-body" }, [
                                    _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _vm._l(_vm.gallaries, function(
                                          gallary
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-6 col-sm-4 col-md-3 col-lg-4  col-xl-3 loadingmore",
                                              staticStyle: { display: "block" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.setSelectedImages(
                                                    gallary.id,
                                                    gallary.gallary_name
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "thumbnail text-center  mb-4",
                                                  class: {
                                                    active:
                                                      _vm.selectedImage ==
                                                      gallary.id
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "thumbnail-imges "
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "img-select d-block",
                                                          attrs: {
                                                            href:
                                                              "javascript:void(0);"
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "img-fluid",
                                                            attrs: {
                                                              src:
                                                                "/gallary/" +
                                                                gallary.gallary_name,
                                                              alt: "image"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        }),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-12" }, [
                                          _c(
                                            "nav",
                                            {
                                              attrs: {
                                                "aria-label": "navigation"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pagination d-flex justify-content-end align-items-center"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mr-2 text-dark"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "(Showing result "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            id: "numbering"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(_vm.meta.to)
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" out of "),
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            id: "totalnumber"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.meta.total
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" )")
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "btn btn-secondary white",
                                                      attrs: {
                                                        href: "#",
                                                        id: "loadMore"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.setLimit()
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Load More")]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      ],
                                      2
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-light",
                  attrs: { type: "button", "data-dismiss": "modal" },
                  on: {
                    click: function($event) {
                      return _vm.toggleModal()
                    }
                  }
                },
                [_c("span", {}, [_vm._v("Choose")])]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }