var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card card-custom gutter-b bg-white border-0" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-3" }, [
            _c(
              "ul",
              {
                staticClass: "nav flex-column nav-pills mb-3",
                attrs: {
                  id: "v-pills-tab1",
                  role: "tablist",
                  "aria-orientation": "vertical"
                }
              },
              [
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: { active: _vm.isActive("general") },
                      attrs: { href: "#general" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setActive("general")
                        }
                      }
                    },
                    [_vm._v("General")]
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: { active: _vm.isActive("notification") },
                      attrs: { href: "#notification" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setActive("notification")
                        }
                      }
                    },
                    [_vm._v("App Local Notification")]
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: { active: _vm.isActive("apploginsignup") },
                      attrs: { href: "#apploginsignup" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setActive("apploginsignup")
                        }
                      }
                    },
                    [_vm._v("Login / Signup")]
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-9" }, [
            _c(
              "div",
              {
                staticClass: "tab-content",
                attrs: { id: "v-pills-tabContent1" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade",
                    class: { "active show": _vm.isActive("general") }
                  },
                  [
                    _c("AppGeneral", {
                      on: { updateLoadingState: _vm.updateLoadingState }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade",
                    class: { "active show": _vm.isActive("notification") }
                  },
                  [
                    _c("AppNotification", {
                      on: { updateLoadingState: _vm.updateLoadingState }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade",
                    class: { "active show": _vm.isActive("apploginsignup") }
                  },
                  [
                    _c("AppLoginSignup", {
                      on: { updateLoadingState: _vm.updateLoadingState }
                    })
                  ],
                  1
                )
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header border-0 align-items-center" },
      [
        _c(
          "h3",
          { staticClass: "card-label mb-0 font-weight-bold text-body" },
          [_vm._v("Mobile App Setting\r\n        ")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }