var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "card card-custom gutter-b bg-white border-0" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("form", [
              _c(
                "div",
                { staticClass: "form-group row" },
                [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("Product Type")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.product_type,
                              expression: "product_type"
                            }
                          ],
                          staticClass:
                            "\n                  single-select\n                  w-100\n                  mb-3\n                  categories-select\n                  ms-offscreen\n                ",
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.product_type = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function($event) {
                                return _vm.setProductType($event.target.value)
                              }
                            ]
                          }
                        },
                        [
                          _c("option", { attrs: { value: "", disabled: "" } }, [
                            _vm._v("Select Product Type")
                          ]),
                          _vm._v(" "),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "simple",
                                disabled:
                                  _vm.product_type == "variable" && _vm.edit
                              }
                            },
                            [
                              _vm._v(
                                "\n                  Simple\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "variable",
                                disabled:
                                  _vm.product_type == "simple" && _vm.edit
                              }
                            },
                            [
                              _vm._v(
                                "\n                  Variable\n                "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.errors.has("product_type")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.errors.get("product_type")
                              )
                            }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v(" ")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "\n                switch-h\n                d-flex\n                justify-content-between\n                align-items-center\n                border\n                p-2\n                mb-3\n              "
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "font-size-h4 text-dark mb-0" },
                          [_vm._v("Is Active?")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "\n                  custom-control\n                  switch\n                  custom-switch-info custom-switch custom-control-inline\n                  mr-0\n                "
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.product_status,
                                  expression: "product_status"
                                }
                              ],
                              staticClass: "custom-control-input",
                              attrs: {
                                type: "checkbox",
                                id: "customSwitchcolor445"
                              },
                              domProps: {
                                value: _vm.product_status,
                                checked: Array.isArray(_vm.product_status)
                                  ? _vm._i(
                                      _vm.product_status,
                                      _vm.product_status
                                    ) > -1
                                  : _vm.product_status
                              },
                              on: {
                                input: function($event) {
                                  return _vm.setProductStatus(
                                    $event.target.value
                                  )
                                },
                                change: function($event) {
                                  var $$a = _vm.product_status,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = _vm.product_status,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.product_status = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.product_status = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.product_status = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticClass: "custom-control-label mr-1",
                              attrs: { for: "customSwitchcolor445" }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.has("product_status")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.errors.get("product_status")
                            )
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v(" ")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "\n                switch-h\n                d-flex\n                justify-content-between\n                align-items-center\n                border\n                p-2\n                mb-3\n              "
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "font-size-h4 text-dark mb-0" },
                          [_vm._v("Is Point")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "\n                  custom-control\n                  switch\n                  custom-switch-info custom-switch custom-control-inline\n                  mr-0\n                "
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.is_points,
                                  expression: "is_points"
                                }
                              ],
                              staticClass: "custom-control-input",
                              attrs: {
                                type: "checkbox",
                                id: "customSwitchcolor446"
                              },
                              domProps: {
                                value: _vm.is_points,
                                checked: Array.isArray(_vm.is_points)
                                  ? _vm._i(_vm.is_points, _vm.is_points) > -1
                                  : _vm.is_points
                              },
                              on: {
                                input: function($event) {
                                  return _vm.setIsPoints($event.target.value)
                                },
                                change: function($event) {
                                  var $$a = _vm.is_points,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = _vm.is_points,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.is_points = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.is_points = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.is_points = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticClass: "custom-control-label mr-1",
                              attrs: { for: "customSwitchcolor446" }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.has("is_points")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("is_points"))
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v(" ")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "\n                switch-h\n                d-flex\n                justify-content-between\n                align-items-center\n                border\n                p-2\n                mb-3\n              "
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "font-size-h4 text-dark mb-0" },
                          [_vm._v("Is Feature")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "\n                  custom-control\n                  switch\n                  custom-switch-info custom-switch custom-control-inline\n                  mr-0\n                "
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.is_featured,
                                  expression: "is_featured"
                                }
                              ],
                              staticClass: "custom-control-input",
                              attrs: { type: "checkbox", id: "features" },
                              domProps: {
                                value: _vm.is_featured,
                                checked: Array.isArray(_vm.is_featured)
                                  ? _vm._i(_vm.is_featured, _vm.is_featured) >
                                    -1
                                  : _vm.is_featured
                              },
                              on: {
                                input: function($event) {
                                  return _vm.setIsFeatured($event.target.value)
                                },
                                change: function($event) {
                                  var $$a = _vm.is_featured,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = _vm.is_featured,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.is_featured = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.is_featured = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.is_featured = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticClass: "custom-control-label mr-1",
                              attrs: { for: "features" }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.has("is_featured")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("is_featured"))
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("Units")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.product_unit,
                              expression: "product_unit"
                            }
                          ],
                          staticClass:
                            "\n                  single-select\n                  w-100\n                  mb-3\n                  categories-select\n                  ms-offscreen\n                ",
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.product_unit = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function($event) {
                                return _vm.setUnit($event.target.value)
                              }
                            ]
                          }
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("Select Unit")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.units, function(unit) {
                            return _c(
                              "option",
                              { domProps: { value: unit.id } },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      unit.detail == null
                                        ? ""
                                        : unit.detail[0]
                                        ? unit.detail[0].name
                                        : ""
                                    ) +
                                    "\n                "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("product_unit")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("product_unit"))
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("Brands")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.brand_id,
                              expression: "brand_id"
                            }
                          ],
                          staticClass:
                            "\n                  single-select\n                  w-100\n                  mb-3\n                  categories-select\n                  ms-offscreen\n                ",
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.brand_id = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function($event) {
                                return _vm.setBrand($event.target.value)
                              }
                            ]
                          }
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("Select Brand")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.brands, function(brand) {
                            return _c(
                              "option",
                              { domProps: { value: brand.brand_id } },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(brand.brand_name) +
                                    "\n                "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("brand_id")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("brand_id"))
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("Product Weight")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.product_weight,
                            expression: "product_weight"
                          }
                        ],
                        staticClass:
                          "form-control round bg-transparent text-dark",
                        attrs: {
                          type: "text",
                          id: "weight",
                          placeholder: "Enter Weight"
                        },
                        domProps: { value: _vm.product_weight },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.product_weight = $event.target.value
                            },
                            function($event) {
                              return _vm.setProductWeight($event.target.value)
                            }
                          ]
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("product_weight")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.errors.get("product_weight")
                            )
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("Price")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.price,
                            expression: "price"
                          }
                        ],
                        staticClass:
                          "form-control round bg-transparent text-dark",
                        attrs: { type: "number", placeholder: "Enter Price" },
                        domProps: { value: _vm.price },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.price = $event.target.value
                            },
                            function($event) {
                              return _vm.setPrice($event.target.value)
                            }
                          ]
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("price")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("price"))
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("Discount Price")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.discount_price,
                            expression: "discount_price"
                          }
                        ],
                        staticClass:
                          "form-control round bg-transparent text-dark",
                        attrs: {
                          type: "number",
                          placeholder: "Enter Discount Price"
                        },
                        domProps: { value: _vm.discount_price },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.discount_price = $event.target.value
                            },
                            function($event) {
                              return _vm.setDiscountPrice($event.target.value)
                            }
                          ]
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("discount_price")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.errors.get("discount_price")
                            )
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("Minimum Order")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.product_min_order,
                            expression: "product_min_order"
                          }
                        ],
                        staticClass:
                          "form-control round bg-transparent text-dark",
                        attrs: {
                          type: "text",
                          id: "type",
                          placeholder: "Enter Minimum Order"
                        },
                        domProps: { value: _vm.product_min_order },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.product_min_order = $event.target.value
                            },
                            function($event) {
                              return _vm.setProductMinOrder($event.target.value)
                            }
                          ]
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("product_min_order")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.errors.get("product_min_order")
                            )
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("Maximum Order")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.product_max_order,
                            expression: "product_max_order"
                          }
                        ],
                        staticClass:
                          "form-control round bg-transparent text-dark",
                        attrs: {
                          type: "text",
                          id: "type-max",
                          placeholder: "Enter Maximum Order"
                        },
                        domProps: { value: _vm.product_max_order },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.product_max_order = $event.target.value
                            },
                            function($event) {
                              return _vm.setProductMaxOrder($event.target.value)
                            }
                          ]
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("product_max_order")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.errors.get("product_max_order")
                            )
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("SKU")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sku,
                            expression: "sku"
                          }
                        ],
                        staticClass:
                          "form-control round bg-transparent text-dark",
                        attrs: {
                          type: "text",
                          id: "type-max",
                          placeholder: "Enter Sku"
                        },
                        domProps: { value: _vm.sku },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.sku = $event.target.value
                            },
                            function($event) {
                              return _vm.setProductsku($event.target.value)
                            }
                          ]
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("sku")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("sku"))
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.product_type == "variable"
                    ? _c("div", { staticClass: "col-md-6" }, [
                        _c("label", [_vm._v("Attributes")]),
                        _vm._v(" "),
                        _c(
                          "fieldset",
                          { staticClass: "form-group mb-3 d-flex" },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.attribute,
                                    expression: "attribute"
                                  }
                                ],
                                staticClass:
                                  "\n                  single-select\n                  w-100\n                  mb-3\n                  categories-select\n                  ms-offscreen\n                ",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.attribute = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("Select Attributes")
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.attributes, function(attributes) {
                                  return _c(
                                    "option",
                                    {
                                      domProps: {
                                        value: attributes.attribute_id
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            attributes.detail == null
                                              ? ""
                                              : attributes.detail[0]
                                              ? attributes.detail[0].name
                                              : ""
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn-secondary btn ml-2 white pt-2 pb-1",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.editChild == true
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.getVariation()
                                  }
                                }
                              },
                              [_vm._v("\n                ADD\n              ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.displayClearBtn,
                                    expression: "displayClearBtn"
                                  }
                                ],
                                staticClass:
                                  "btn-secondary btn ml-2 white pt-2 pb-1",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.editChild == true
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.unsetVariationData()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                Remove\n              "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.errors.has("attributes")
                          ? _c("small", {
                              staticClass: "form-text text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.errors.get("attributes")
                                )
                              }
                            })
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.product_type == "variable"
                    ? _c("div", { staticClass: "col-md-6" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.variations, function(variation, index) {
                    return _vm.product_type == "variable"
                      ? [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", [
                              _vm._v(
                                _vm._s(
                                  variation.detail == null
                                    ? ""
                                    : variation.detail[0].name
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "fieldset",
                              { staticClass: "form-group mb-3 d-flex" },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.variationData[
                                            "variation_" +
                                              variation.attribute_id
                                          ],
                                        expression:
                                          "\n                    variationData['variation_' + variation.attribute_id]\n                  "
                                      }
                                    ],
                                    staticClass:
                                      "\n                    single-select\n                    w-100\n                    mb-3\n                    categories-select\n                    ms-offscreen\n                  ",
                                    attrs: {
                                      multiple: "",
                                      disabled: _vm.editChild == true
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.variationData,
                                            "variation_" +
                                              variation.attribute_id,
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function($event) {
                                          return _vm.setVariations(
                                            "variation_" +
                                              variation.attribute_id
                                          )
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          value: "",
                                          selected: "",
                                          disabled: ""
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Select\n                    " +
                                            _vm._s(
                                              variation.detail == null
                                                ? ""
                                                : variation.detail[0].name
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(variation.variations, function(
                                      variationDetail
                                    ) {
                                      return _c(
                                        "option",
                                        {
                                          attrs: {
                                            set: (_vm.allVariations[
                                              variationDetail.id
                                            ] =
                                              variationDetail.detail == null
                                                ? ""
                                                : variationDetail.detail[0]
                                                    .name)
                                          },
                                          domProps: {
                                            value: variationDetail.id
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                variationDetail.detail == null
                                                  ? ""
                                                  : variationDetail.detail[0]
                                                      .name
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" })
                        ]
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.combinationDetail, function(
                    combination_detail,
                    index
                  ) {
                    return _vm.product_type == "variable"
                      ? [
                          index == 0
                            ? [
                                _c("div", { staticClass: "col-md-3" }, [
                                  _vm._v("Variant")
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-3" }, [
                                  _vm._v("Variat Price")
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-3" }, [
                                  _vm._v("SKU")
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-3" }, [
                                  _vm._v("Image")
                                ])
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-3 mt-3" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(combination_detail.variation_name) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-3 mt-3" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.combinationPrice[
                                      combination_detail.price
                                    ],
                                  expression:
                                    "combinationPrice[combination_detail.price]"
                                }
                              ],
                              attrs: {
                                type: "text",
                                name: combination_detail.price
                              },
                              domProps: {
                                value:
                                  _vm.combinationPrice[combination_detail.price]
                              },
                              on: {
                                input: [
                                  function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.combinationPrice,
                                      combination_detail.price,
                                      $event.target.value
                                    )
                                  },
                                  function($event) {
                                    return _vm.setCombinationPrice(
                                      combination_detail.price,
                                      _vm.combinationPrice[
                                        combination_detail.price
                                      ]
                                    )
                                  }
                                ]
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-3 mt-3" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.combinationSku[combination_detail.sku],
                                  expression:
                                    "combinationSku[combination_detail.sku]"
                                }
                              ],
                              attrs: {
                                type: "text",
                                name: combination_detail.sku
                              },
                              domProps: {
                                value:
                                  _vm.combinationSku[combination_detail.sku]
                              },
                              on: {
                                input: [
                                  function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.combinationSku,
                                      combination_detail.sku,
                                      $event.target.value
                                    )
                                  },
                                  function($event) {
                                    return _vm.setCombinationSku(
                                      combination_detail.sku,
                                      $event.target.value
                                    )
                                  }
                                ]
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-3 mt-3" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.toggleImageSelect(
                                      combination_detail.gallary
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                Upload Media\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.combinationGalleryPath[
                              combination_detail.gallary
                            ] != "" &&
                            _vm.combinationGalleryPath[
                              combination_detail.gallary
                            ] != null
                              ? _c("img", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px"
                                  },
                                  attrs: {
                                    src:
                                      _vm.combinationGalleryPath[
                                        combination_detail.gallary
                                      ]
                                  }
                                })
                              : _vm._e()
                          ])
                        ]
                      : _vm._e()
                  })
                ],
                2
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 d-flex justify-content-end" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-dark swipe-to-top cta",
              class: { active: _vm.isActive("info-tab") },
              attrs: { "data-toggle": "pill" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.setActive("info-tab")
                }
              }
            },
            [_vm._v("Back")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-primary cta",
              class: { active: _vm.isActive("seo-tab") },
              attrs: { "data-toggle": "pill", href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.setActive("seo-tab")
                }
              }
            },
            [_vm._v("Continue")]
          )
        ])
      ]),
      _vm._v(" "),
      _c("attach-image", {
        attrs: { showModal: _vm.showModal },
        on: { toggleImageSelect: _vm.toggleImageSelect, setImage: _vm.setImage }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header border-0 align-items-center" },
      [
        _c(
          "h3",
          { staticClass: "card-label mb-0 font-weight-bold text-body" },
          [_vm._v("\n        Advance Information\n      ")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }