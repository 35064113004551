var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c("fieldset", [
        _c("div", { staticClass: "custom-control custom-checkbox" }, [
          _c("input", {
            staticClass: "custom-control-input",
            attrs: {
              type: "checkbox",
              name: "sociallite_login",
              id: "sociallite_login"
            },
            domProps: {
              checked:
                _vm.loginCredential.sociallite_login == "1" ? "checked" : ""
            },
            on: {
              change: function($event) {
                return _vm.check($event)
              }
            }
          }),
          _c(
            "label",
            {
              staticClass: "custom-control-label",
              attrs: { for: "sociallite_login" }
            },
            [_vm._v("SocialLite Allow")]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Facebook Client Id")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.loginCredential.facebook_client_id,
            expression: "loginCredential.facebook_client_id"
          }
        ],
        ref: "site_name",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.loginCredential.facebook_client_id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(
              _vm.loginCredential,
              "facebook_client_id",
              $event.target.value
            )
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Facebook Client Secret")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.loginCredential.facebook_client_secret,
            expression: "loginCredential.facebook_client_secret"
          }
        ],
        ref: "site_name",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.loginCredential.facebook_client_secret },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(
              _vm.loginCredential,
              "facebook_client_secret",
              $event.target.value
            )
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Facebook Redirect")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.loginCredential.facebook_redirect,
            expression: "loginCredential.facebook_redirect"
          }
        ],
        ref: "site_name",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.loginCredential.facebook_redirect },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(
              _vm.loginCredential,
              "facebook_redirect",
              $event.target.value
            )
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Google Client Id")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.loginCredential.google_client_id,
            expression: "loginCredential.google_client_id"
          }
        ],
        ref: "site_name",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.loginCredential.google_client_id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(
              _vm.loginCredential,
              "google_client_id",
              $event.target.value
            )
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Google Client Secret")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.loginCredential.google_client_secret,
            expression: "loginCredential.google_client_secret"
          }
        ],
        ref: "site_name",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.loginCredential.google_client_secret },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(
              _vm.loginCredential,
              "google_client_secret",
              $event.target.value
            )
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Google Redirect")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.loginCredential.google_redirect,
            expression: "loginCredential.google_redirect"
          }
        ],
        ref: "site_name",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.loginCredential.google_redirect },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(
              _vm.loginCredential,
              "google_redirect",
              $event.target.value
            )
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("br"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit" },
          on: {
            click: function($event) {
              return _vm.updateSetting()
            }
          }
        },
        [_vm._v("Submit")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }