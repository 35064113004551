var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0"
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("form", [
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Quotations")
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "form-group mb-3" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedQuotation,
                                      expression: "selectedQuotation"
                                    }
                                  ],
                                  staticClass:
                                    "js-states form-control bg-transparent",
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.selectedQuotation = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function($event) {
                                        return _vm.getQuotation()
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("Select Quotation")
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.quotations, function(quotation) {
                                    return _c(
                                      "option",
                                      { domProps: { value: quotation.id } },
                                      [
                                        _vm._v(
                                          "\n                              " +
                                            _vm._s(quotation.id) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Warehouses")
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "form-group mb-3" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.purchase.warehouse_id,
                                      expression: "purchase.warehouse_id"
                                    }
                                  ],
                                  staticClass:
                                    "js-states form-control bg-transparent",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.purchase,
                                        "warehouse_id",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("Select Warehouse")
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.warehouses, function(warehouse) {
                                    return _c(
                                      "option",
                                      {
                                        domProps: {
                                          value: warehouse.warehouse_id
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                              " +
                                            _vm._s(warehouse.warehouse_name) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.errors.has("warehouse_id")
                                ? _c("small", {
                                    staticClass: "form-text text-danger",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.errors.get("warehouse_id")
                                      )
                                    }
                                  })
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Suppliers")
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "form-group mb-3" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.purchase.supplier_id,
                                      expression: "purchase.supplier_id"
                                    }
                                  ],
                                  staticClass:
                                    "js-states form-control bg-transparent",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.purchase,
                                        "supplier_id",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("Select Suppliers")
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.suppliers, function(supplier) {
                                    return _c(
                                      "option",
                                      {
                                        domProps: {
                                          value: supplier.supplier_id
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                              " +
                                            _vm._s(
                                              supplier.supplier_first_name
                                            ) +
                                            "\n                              " +
                                            _vm._s(
                                              supplier.supplier_last_name
                                            ) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.errors.has("supplier_id")
                                ? _c("small", {
                                    staticClass: "form-text text-danger",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.errors.get("supplier_id")
                                      )
                                    }
                                  })
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Purchase Date")
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "form-group mb-3" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.purchase.purchase_date,
                                    expression: "purchase.purchase_date"
                                  }
                                ],
                                staticClass: "form-control bg-white",
                                attrs: { type: "date", value: "" },
                                domProps: { value: _vm.purchase.purchase_date },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.purchase,
                                      "purchase_date",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _vm.errors.has("purchase_date")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("purchase_date")
                                    )
                                  }
                                })
                              : _vm._e()
                          ])
                        ])
                      ])
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0"
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("label", { staticClass: "text-body" }, [
                          _vm._v("Products")
                        ]),
                        _vm._v(" "),
                        _c(
                          "fieldset",
                          { staticClass: "form-group mb-3 d-flex" },
                          [
                            _c("multiselect", {
                              attrs: {
                                options: _vm.products,
                                "custom-label": _vm.nameWithLang,
                                placeholder: "Select Product",
                                label: "name",
                                "track-by": "product_id",
                                multiple: true,
                                taggable: true
                              },
                              on: {
                                select: _vm.addProduct,
                                remove: _vm.removeItem
                              },
                              model: {
                                value: _vm.product_id,
                                callback: function($$v) {
                                  _vm.product_id = $$v
                                },
                                expression: "product_id"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.errors.has("product_id")
                          ? _c("small", {
                              staticClass: "form-text text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.errors.get("product_id")
                                )
                              }
                            })
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _vm.combination_products.length > 0
                        ? _c("div", { staticClass: "col-md-12" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Select Variation Product")
                            ]),
                            _vm._v(" "),
                            _c(
                              "fieldset",
                              { staticClass: "form-group mb-3 d-flex" },
                              [
                                _c("multiselect", {
                                  attrs: {
                                    options: _vm.combination_products,
                                    placeholder: "Select Product",
                                    label: "title",
                                    "track-by": "product_combination_id",
                                    multiple: true,
                                    taggable: true
                                  },
                                  on: {
                                    select: _vm.addCombinationProduct,
                                    remove: _vm.removeItem
                                  },
                                  model: {
                                    value: _vm.combination_product_id,
                                    callback: function($$v) {
                                      _vm.combination_product_id = $$v
                                    },
                                    expression: "combination_product_id"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.errors.has("product_id")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("product_id")
                                    )
                                  }
                                })
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.display_table
                        ? _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                {
                                  staticClass: "table table-striped text-body"
                                },
                                [
                                  _c("thead", [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "border-0 header-heading",
                                          attrs: { scope: "col" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                              Name\n                            "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "border-0 header-heading",
                                          attrs: { scope: "col" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                              Quantity\n                            "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "border-0 header-heading",
                                          attrs: { scope: "col" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                              Price " +
                                              _vm._s(_vm.getCurrencyTitle()) +
                                              "\n                            "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "border-0 header-heading",
                                          attrs: { scope: "col" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                              Amount " +
                                              _vm._s(_vm.getCurrencyTitle()) +
                                              "\n                            "
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    [
                                      _vm._l(_vm.selectedProducts, function(
                                        selectedProduct,
                                        index
                                      ) {
                                        return _c("tr", {}, [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(selectedProduct.title)
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.purchase.qty[index],
                                                    expression:
                                                      "purchase.qty[index]"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "Enter Quantity"
                                                },
                                                domProps: {
                                                  value: _vm.purchase.qty[index]
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.computeValues()
                                                  },
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.purchase.qty,
                                                      index,
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _vm.errors.has("qty." + index)
                                                ? _c("small", {
                                                    staticClass:
                                                      "form-text text-danger",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.errors.get(
                                                          "qty." + index
                                                        )
                                                      )
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.purchase.price[index],
                                                    expression:
                                                      "purchase.price[index]"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "Enter Price"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.purchase.price[index]
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.computeValues()
                                                  },
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.purchase.price,
                                                      index,
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _vm.errors.has("price." + index)
                                                ? _c("small", {
                                                    staticClass:
                                                      "form-text text-danger",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.errors.get(
                                                          "price." + index
                                                        )
                                                      )
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c("input", {
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  readonly: "",
                                                  placeholder: "Enter Price"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.purchase.price[index] &&
                                                    _vm.purchase.qty[index] &&
                                                    !isNaN(
                                                      _vm.purchase.price[index]
                                                    ) &&
                                                    !isNaN(
                                                      _vm.purchase.qty[index]
                                                    )
                                                      ? _vm.purchase.price[
                                                          index
                                                        ] *
                                                        _vm.purchase.qty[index]
                                                      : 0
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.computeValues()
                                                  }
                                                }
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "confirm-delete",
                                                  attrs: {
                                                    href: "#",
                                                    title: "Delete"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.removeItem(
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-trash-alt"
                                                  })
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            attrs: {
                                              type: "hidden",
                                              set: (_vm.purchase.product_id[
                                                index
                                              ] = selectedProduct.product_id)
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("input", {
                                            attrs: {
                                              type: "hidden",
                                              set: (_vm.purchase.product_combination_id[
                                                index
                                              ] =
                                                selectedProduct.product_combination_id)
                                            }
                                          })
                                        ])
                                      }),
                                      _vm._v(" "),
                                      _c("tr", [
                                        _c("td"),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm.errors.has("qty")
                                            ? _c("small", {
                                                staticClass:
                                                  "form-text text-danger",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.errors.get("qty")
                                                  )
                                                }
                                              })
                                            : _vm._e()
                                        ]),
                                        _vm._v(" "),
                                        _c("td", { attrs: { colspan: "3" } }, [
                                          _vm.errors.has("price")
                                            ? _c("small", {
                                                staticClass:
                                                  "form-text text-danger",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.errors.get("price")
                                                  )
                                                }
                                              })
                                            : _vm._e()
                                        ])
                                      ])
                                    ],
                                    2
                                  )
                                ]
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0"
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row mb-3" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("label", [_vm._v("Note")]),
                          _vm._v(" "),
                          _c("fieldset", { staticClass: "form-group mb-3" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.purchase.description,
                                  expression: "purchase.description"
                                }
                              ],
                              staticClass: "form-control",
                              staticStyle: { height: "130px" },
                              attrs: {
                                id: "label-textarea",
                                rows: "6",
                                name: "notes",
                                placeholder: "Please add some note",
                                spellcheck: "false"
                              },
                              domProps: { value: _vm.purchase.description },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.purchase,
                                    "description",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _vm.errors.has("description")
                            ? _c("small", {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.errors.get("description")
                                  )
                                }
                              })
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row justify-content-end" }, [
                        _c("div", { staticClass: "col-12 col-md-3" }, [
                          _c("div", [
                            _c(
                              "table",
                              { staticClass: "table right-table mb-0" },
                              [
                                _c("tbody", [
                                  _c(
                                    "tr",
                                    {
                                      staticClass:
                                        "\n                                d-flex\n                                align-items-center\n                                justify-content-between\n                              "
                                    },
                                    [
                                      _c("th", { staticClass: "border-0" }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "\n                                    font-size-h5\n                                    mb-0\n                                    font-size-bold\n                                    text-dark\n                                  "
                                          },
                                          [
                                            _vm._v(
                                              "\n                                  Total " +
                                                _vm._s(_vm.getCurrencyTitle()) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "\n                                  border-0\n                                  justify-content-end\n                                  d-flex\n                                  text-black-50\n                                  font-size-base\n                                "
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.purchase.total_amount
                                              ) +
                                              "\n                              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-12 d-flex justify-content-end" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function($event) {
                                  return _vm.addPurchase()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                      Submit\n                    "
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "\n                  card card-custom\n                  gutter-b\n                  bg-transparent\n                  shadow-none\n                  border-0\n                "
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "\n                    card-header\n                    align-items-center\n                    border-bottom-dark\n                    px-0\n                  "
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body"
                    },
                    [
                      _vm._v(
                        "\n                      Purchase\n                    "
                      )
                    ]
                  )
                ])
              ]
            )
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }