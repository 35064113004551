var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
              _c(
                "div",
                { staticClass: "card card-custom gutter-b bg-white border-0" },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("label", { staticClass: "text-body" }, [
                        _vm._v("Products")
                      ]),
                      _vm._v(" "),
                      _c(
                        "fieldset",
                        { staticClass: "form-group mb-3 d-flex" },
                        [
                          _c("multiselect", {
                            attrs: {
                              options: _vm.products,
                              "custom-label": _vm.nameWithLang,
                              placeholder: "Select Product",
                              label: "name",
                              "track-by": "product_id",
                              multiple: true,
                              taggable: true
                            },
                            on: {
                              select: _vm.addProduct,
                              remove: _vm.removeItem
                            },
                            model: {
                              value: _vm.product_id,
                              callback: function($$v) {
                                _vm.product_id = $$v
                              },
                              expression: "product_id"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.errors.has("product_id")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("product_id"))
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.combination_products.length > 0
                      ? _c("div", { staticClass: "col-md-12" }, [
                          _c("label", { staticClass: "text-body" }, [
                            _vm._v("Select Variation Product")
                          ]),
                          _vm._v(" "),
                          _c(
                            "fieldset",
                            { staticClass: "form-group mb-3 d-flex" },
                            [
                              _c("multiselect", {
                                attrs: {
                                  options: _vm.combination_products,
                                  placeholder: "Select Product",
                                  label: "title",
                                  "track-by": "product_combination_id",
                                  multiple: true,
                                  taggable: true
                                },
                                on: {
                                  select: _vm.addCombinationProduct,
                                  remove: _vm.removeItem
                                },
                                model: {
                                  value: _vm.combination_product_id,
                                  callback: function($$v) {
                                    _vm.combination_product_id = $$v
                                  },
                                  expression: "combination_product_id"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.errors.has("product_id")
                            ? _c("small", {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.errors.get("product_id")
                                  )
                                }
                              })
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.display_table
                      ? _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "table-responsive" }, [
                            _c(
                              "table",
                              { staticClass: "table table-striped  text-body" },
                              [
                                _vm._m(1),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  [
                                    _vm._l(_vm.selectedProducts, function(
                                      selectedProduct,
                                      index
                                    ) {
                                      return _c("tr", {}, [
                                        _c("td", [
                                          _vm._v(
                                            " " + _vm._s(selectedProduct.title)
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.stock.warehouse_id[
                                                      index
                                                    ],
                                                  expression:
                                                    "stock.warehouse_id[index]"
                                                }
                                              ],
                                              staticClass:
                                                " js-states form-control bg-transparent",
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.stock.warehouse_id,
                                                    index,
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "",
                                                    disabled: ""
                                                  }
                                                },
                                                [_vm._v("Select Warehouse")]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(_vm.warehouses, function(
                                                warehouse
                                              ) {
                                                return _c(
                                                  "option",
                                                  {
                                                    domProps: {
                                                      value:
                                                        warehouse.warehouse_id
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\r\n                                                                    " +
                                                        _vm._s(
                                                          warehouse.warehouse_name
                                                        ) +
                                                        "\r\n                                                                "
                                                    )
                                                  ]
                                                )
                                              })
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _vm.errors.has(
                                            "warehouse_id." + index
                                          )
                                            ? _c("small", {
                                                staticClass:
                                                  "form-text text-danger",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.errors.get(
                                                      "qty." + index
                                                    )
                                                  )
                                                }
                                              })
                                            : _vm._e()
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.stock.stock_status[
                                                      index
                                                    ],
                                                  expression:
                                                    "stock.stock_status[index]"
                                                }
                                              ],
                                              staticClass:
                                                " js-states form-control bg-transparent",
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.stock.stock_status,
                                                    index,
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "",
                                                    disabled: ""
                                                  }
                                                },
                                                [_vm._v("Select Status")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                { attrs: { value: "IN" } },
                                                [_vm._v("IN")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                { attrs: { value: "OUT" } },
                                                [_vm._v("OUT")]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.errors.has(
                                            "stock_status." + index
                                          )
                                            ? _c("small", {
                                                staticClass:
                                                  "form-text text-danger",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.errors.get(
                                                      "stock_status." + index
                                                    )
                                                  )
                                                }
                                              })
                                            : _vm._e()
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: " text-center" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.stock.qty[index],
                                                  expression: "stock.qty[index]"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                placeholder: "Enter Quantity"
                                              },
                                              domProps: {
                                                value: _vm.stock.qty[index]
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.stock.qty,
                                                    index,
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.errors.has("qty." + index)
                                              ? _c("small", {
                                                  staticClass:
                                                    "form-text text-danger",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.errors.get(
                                                        "qty." + index
                                                      )
                                                    )
                                                  }
                                                })
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: " text-center" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.stock.price[index],
                                                  expression:
                                                    "stock.price[index]"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                placeholder: "Enter Price"
                                              },
                                              domProps: {
                                                value: _vm.stock.price[index]
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.stock.price,
                                                    index,
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.errors.has("price." + index)
                                              ? _c("small", {
                                                  staticClass:
                                                    "form-text text-danger",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.errors.get(
                                                        "price." + index
                                                      )
                                                    )
                                                  }
                                                })
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          attrs: {
                                            type: "hidden",
                                            set: (_vm.stock.product_id[index] =
                                              selectedProduct.product_id)
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("input", {
                                          attrs: {
                                            type: "hidden",
                                            set: selectedProduct.product_combination_id
                                              ? (_vm.stock.product_combination_id[
                                                  index
                                                ] =
                                                  selectedProduct.product_combination_id)
                                              : []
                                          }
                                        })
                                      ])
                                    }),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td"),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm.errors.has("warehouse_id")
                                          ? _c("small", {
                                              staticClass:
                                                "form-text text-danger",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.errors.get("warehouse_id")
                                                )
                                              }
                                            })
                                          : _vm._e()
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm.errors.has("stock_status")
                                          ? _c("small", {
                                              staticClass:
                                                "form-text text-danger",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.errors.get("stock_status")
                                                )
                                              }
                                            })
                                          : _vm._e()
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { attrs: { colspan: "2" } }, [
                                        _vm.errors.has("qty")
                                          ? _c("small", {
                                              staticClass:
                                                "form-text text-danger",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.errors.get("qty")
                                                )
                                              }
                                            })
                                          : _vm._e()
                                      ])
                                    ])
                                  ],
                                  2
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e()
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
              _c(
                "div",
                { staticClass: "card card-custom gutter-b bg-white border-0" },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function($event) {
                              return _vm.addstock()
                            }
                          }
                        },
                        [_vm._v("Submit")]
                      )
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom gutter-b bg-transparent shadow-none border-0"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center  border-bottom-dark px-0"
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body"
                    },
                    [
                      _vm._v(
                        "\r\n                                            Add Stock\r\n                                        "
                      )
                    ]
                  )
                ])
              ]
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          { staticClass: "border-0  header-heading", attrs: { scope: "col" } },
          [_vm._v("Name")]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "border-0  header-heading", attrs: { scope: "col" } },
          [_vm._v("WareHouse")]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "border-0  header-heading", attrs: { scope: "col" } },
          [_vm._v("Stock Status")]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "border-0  header-heading", attrs: { scope: "col" } },
          [_vm._v("Quantity")]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "border-0  header-heading", attrs: { scope: "col" } },
          [_vm._v("Price")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }