var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group row" }, [
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Header Style")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.header_style,
                expression: "theme.header_style"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "header_style",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Style 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Style 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style3" } }, [_vm._v("Style 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style4" } }, [_vm._v("Style 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style5" } }, [_vm._v("Style 5")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style6" } }, [_vm._v("Style 6")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style7" } }, [_vm._v("Style 7")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style8" } }, [_vm._v("Style 8")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style9" } }, [_vm._v("Style 9")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style10" } }, [_vm._v("Style 10")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Slider Style")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.slider_style,
                expression: "theme.slider_style"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "slider_style",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Style 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Style 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style3" } }, [_vm._v("Style 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style4" } }, [_vm._v("Style 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style5" } }, [_vm._v("Style 5")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Banner")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.banner_style,
                expression: "theme.banner_style"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "banner_style",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Style 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Style 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style3" } }, [_vm._v("Style 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style4" } }, [_vm._v("Style 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style5" } }, [_vm._v("Style 5")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style6" } }, [_vm._v("Style 6")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style7" } }, [_vm._v("Style 7")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style8" } }, [_vm._v("Style 8")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style9" } }, [_vm._v("Style 9")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style10" } }, [_vm._v("Style 10")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style11" } }, [_vm._v("Style 11")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style12" } }, [_vm._v("Style 12")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style13" } }, [_vm._v("Style 13")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style14" } }, [_vm._v("Style 14")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style15" } }, [_vm._v("Style 15")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style16" } }, [_vm._v("Style 16")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style17" } }, [_vm._v("Style 17")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style18" } }, [_vm._v("Style 18")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style19" } }, [_vm._v("Style 19")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Footer")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.Footer_style,
                expression: "theme.Footer_style"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "Footer_style",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Style 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Style 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style3" } }, [_vm._v("Style 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style4" } }, [_vm._v("Style 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style5" } }, [_vm._v("Style 5")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style6" } }, [_vm._v("Style 6")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style7" } }, [_vm._v("Style 7")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style8" } }, [_vm._v("Style 8")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style9" } }, [_vm._v("Style 9")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style10" } }, [_vm._v("Style 10")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Card Style")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.card_style,
                expression: "theme.card_style"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "card_style",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Style 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Style 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style3" } }, [_vm._v("Style 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style4" } }, [_vm._v("Style 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style5" } }, [_vm._v("Style 5")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style6" } }, [_vm._v("Style 6")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style7" } }, [_vm._v("Style 7")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style8" } }, [_vm._v("Style 8")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style9" } }, [_vm._v("Style 9")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style10" } }, [_vm._v("Style 10")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style11" } }, [_vm._v("Style 11")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style12" } }, [_vm._v("Style 12")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style13" } }, [_vm._v("Style 13")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style14" } }, [_vm._v("Style 14")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style15" } }, [_vm._v("Style 15")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style16" } }, [_vm._v("Style 16")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style17" } }, [_vm._v("Style 17")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style18" } }, [_vm._v("Style 18")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style19" } }, [_vm._v("Style 19")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style20" } }, [_vm._v("Style 20")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Card Page Style")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.cart_page,
                expression: "theme.cart_page"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "cart_page",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Style 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Style 2")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Blog Page Style")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.blog,
                expression: "theme.blog"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "blog",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Style 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Style 2")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Contact Us Page Style")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.contact_us,
                expression: "theme.contact_us"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "contact_us",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Style 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Style 2")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Login Page Style")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.login,
                expression: "theme.login"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "login",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Style 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Style 2")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Product Page Style")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.product_detail,
                expression: "theme.product_detail"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "product_detail",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Style 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Style 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style3" } }, [_vm._v("Style 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style4" } }, [_vm._v("Style 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style5" } }, [_vm._v("Style 5")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Shop Page Style")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.shop,
                expression: "theme.shop"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "shop",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Style 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Style 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style3" } }, [_vm._v("Style 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style4" } }, [_vm._v("Style 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style5" } }, [_vm._v("Style 5")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Website Color Scheme")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.color,
                expression: "theme.color"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "color",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style" } }, [_vm._v("Blue")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "red" } }, [_vm._v("Red")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "black" } }, [_vm._v("Black")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "bilobaflower" } }, [
              _vm._v("Biloba Flower")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "brickred" } }, [
              _vm._v("Brickred")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "brown" } }, [_vm._v("Brown")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "darkblue" } }, [
              _vm._v("Dark Blue")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "darkbrown" } }, [
              _vm._v("Dark Brown")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "darkgray" } }, [
              _vm._v("Dark Gray")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "ferozi" } }, [_vm._v("Ferozi")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "green" } }, [_vm._v("Green")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "lightblue" } }, [
              _vm._v("Light Blue")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "lightbrown" } }, [
              _vm._v("Light Brown")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "lightferozi" } }, [
              _vm._v("Light Ferozi")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "lightpink" } }, [
              _vm._v("Light Pink")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "moss" } }, [_vm._v("Moss")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "orange" } }, [_vm._v("Orange")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "panetone" } }, [
              _vm._v("Pane Tone")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "parrot" } }, [_vm._v("Parrot")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "pink" } }, [_vm._v("Pink")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "purple" } }, [_vm._v("Purple")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "seapink" } }, [_vm._v("Sea Pink")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "smokegray" } }, [
              _vm._v("Smoke Gray")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "voiltsky" } }, [
              _vm._v("Voilt Sky")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "yellow" } }, [_vm._v("Yellow")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit" },
          on: {
            click: function($event) {
              return _vm.updateSetting()
            }
          }
        },
        [_vm._v("Submit")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }