var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header header-fixed", attrs: { id: "tc_header" } },
    [
      _c(
        "div",
        {
          staticClass:
            "container-fluid d-flex align-items-stretch justify-content-between"
        },
        [
          _c(
            "div",
            {
              staticClass: "header-menu-wrapper header-menu-wrapper-left",
              attrs: { id: "tc_header_menu_wrapper" }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "header-menu header-menu-mobile header-menu-layout-default",
                  attrs: { id: "tc_header_menu" }
                },
                [
                  _c("ul", { staticClass: "menu-nav" }, [
                    _c(
                      "li",
                      {
                        staticClass:
                          "menu-item menu-item-open menu-item-here menu-item-submenu menu-item-rel menu-item-open menu-item-here menu-item-active p-0",
                        attrs: {
                          "data-menu-toggle": "click",
                          "aria-haspopup": "true"
                        },
                        on: {
                          click: function($event) {
                            return _vm.setBurgerMenu()
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "btn  btn-clean btn-dropdown mr-0 p-0",
                            attrs: { id: "tc_aside_toggle" }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "svg-icon svg-icon-xl svg-icon-primary"
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "bi bi-list",
                                    attrs: {
                                      width: "24px",
                                      height: "24px",
                                      viewBox: "0 0 16 16",
                                      fill: "currentColor",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "fill-rule": "evenodd",
                                        d:
                                          "M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "topbar" }, [
            _vm.$parent.permissions.includes("pos")
              ? _c(
                  "div",
                  { staticClass: "posicon d-lg-block d-none" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-primary white mr-2",
                        attrs: { to: "/admin/pos" }
                      },
                      [_vm._v("POS")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "posicon d-lg-block d-none" }, [
              _c(
                "div",
                { staticClass: "dropdown", class: { show: _vm.showdropdown } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "topbar-item",
                      attrs: {
                        "data-toggle": "dropdown",
                        "data-display": "static",
                        "aria-expanded": _vm.showdropdown
                      },
                      on: {
                        click: function($event) {
                          return _vm.showDropDown()
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "btn btn-icon w-auto btn-clean d-flex align-items-center pr-1 pl-3"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-dark-50 font-size-base d-none d-xl-inline mr-3"
                            },
                            [
                              _vm._v(
                                "\r\n                                            " +
                                  _vm._s(_vm.username)
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "symbol symbol-35 symbol-light-success"
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "symbol-label font-size-h5 " },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-person-fill",
                                      attrs: {
                                        width: "20px",
                                        height: "20px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu dropdown-menu-right",
                      class: { show: _vm.showdropdown },
                      staticStyle: { "min-width": "150px" }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/admin/profile" }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "svg-icon svg-icon-xl svg-icon-primary mr-2"
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "feather feather-user",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "20px",
                                    height: "20px",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    stroke: "currentColor",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("circle", {
                                    attrs: { cx: "12", cy: "7", r: "4" }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(
                            "\r\n\t\t\t\t\t\t\t\t\t\tEdit Profile\r\n\t\t\t\t\t\t\t\t\t"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.logOut()
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "svg-icon svg-icon-xl svg-icon-primary mr-2"
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "feather feather-power",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "20px",
                                    height: "20px",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    stroke: "currentColor",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M18.36 6.64a9 9 0 1 1-12.73 0"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("line", {
                                    attrs: {
                                      x1: "12",
                                      y1: "2",
                                      x2: "12",
                                      y2: "12"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(
                            "\r\n\t\t\t\t\t\t\t\t\t\tLogout\r\n\t\t\t\t\t\t\t\t\t"
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }