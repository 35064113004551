var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group row" }, [
    _c("ul", { staticClass: "list-unstyled mb-0 login-forms" }, [
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: {
                type: "checkbox",
                name: "authenticate_with_email_password",
                id: "webloginsignup2"
              },
              domProps: {
                checked:
                  _vm.websiteloginsignup.authenticate_with_email_password == "1"
                    ? "checked"
                    : ""
              },
              on: {
                change: function($event) {
                  return _vm.check($event)
                }
              }
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "webloginsignup2" }
              },
              [_vm._v("Email / Password")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: {
                type: "checkbox",
                name: "authenticate_with_facebook",
                id: "webloginsignup4"
              },
              domProps: {
                checked:
                  _vm.websiteloginsignup.authenticate_with_facebook == "1"
                    ? "checked"
                    : ""
              },
              on: {
                change: function($event) {
                  return _vm.check($event)
                }
              }
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "webloginsignup4" }
              },
              [_vm._v("Facebook")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: {
                type: "checkbox",
                name: "authenticate_with_google",
                id: "webloginsignup5"
              },
              domProps: {
                checked:
                  _vm.websiteloginsignup.authenticate_with_google == "1"
                    ? "checked"
                    : ""
              },
              on: {
                change: function($event) {
                  return _vm.check($event)
                }
              }
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "webloginsignup5" }
              },
              [_vm._v("Google")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _c("br"),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "submit" },
            on: {
              click: function($event) {
                return _vm.updateSetting()
              }
            }
          },
          [_vm._v("Submit")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }